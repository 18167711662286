import react from '../assets/react.jpg'
import javascript from '../assets/javascript.jpg'
import web from '../assets/web.jpg'
import java from '../assets/java.png'
export const achievementData = {
    ach1 : "Develop from scratch to deployment",
    ach2 : "Optimize and improve code",
    ach3 : "Implement functional features in projects",
    ach4 : "security",
    list: [ {
        id : 1,
        title : 'fullstack',
        field : 'Fullstack'
        
    }],
    achievements : [
        {
            id : 1,
            title : 'MERN Stack React Node Ecommerce from Scratch to Deployment ',
            details : 'React course from Udemy',
            date : '2021',
            field : 'Fullstack',
            image : react
        },
        {
            id : 2,
            title : 'Advanced JavaScript Concepts ',
            details : 'Javasctipt advanced topics',
            date : '2020',
            field : 'Javascript',
            image : javascript
        },
        {
            id : 3,
            title : 'The Web Developer Bootcamp ',
            details : 'HTML, CSS, JavaScript, React, Node, MongoDB and More',
            date : '2020',
            field : 'Web Development',
            image : web
        },
        {
            id : 3,
            title : 'Java Programming Masterclass for Software Developers ',
            details : 'Java Programming Language Course from Udemy',
            date : '2019',
            field : 'Java Programming',
            image : java
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/