export const experienceData = [
    {
        id: 1,
        company: 'Fullstack appications',
        jobtitle: 'NodeJS, React, Redux, MongoDB MERN',
       
    },
    {
        id: 2,
        company: 'Programming languages',
        jobtitle: 'Assembly, C, C#, Java, JavaScript, Python, SQL ',
       
    },
    {
        id: 3,
        company: 'Knowledge  ',
        jobtitle: 'AWS, Firebase, Heroku Cloud Systems',
       
    },
    {
        id: 4,
        company: 'Java applications',
        jobtitle: '',
       
    },
    {
        id: 5,
        company: 'Skilled in',
        jobtitle: 'NoSQL/SQL DBMS, REST APIs, ES6, HTML5, JSON, ReactJS, Redux, NodeJS, Express, REST APIs, AJAX ',
       
    },
]