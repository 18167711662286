import freechat from '../assets/png/chat.png'
import furniture from '../assets/png/shop.jpg'
import sybil from '../assets/png/sybil.jpg'
import shoes from '../assets/png/shoes-store.png'
import shopme from '../assets/png/shopme.png'



export const projectsData = [
    {
        id: 1,
        projectName: 'FreeChat App',
        projectDesc: 'Social network with options to create channels for private or public messages between network users. App runs on Firebase server using realtime database. For guests default username "guest@gmail.com" and password "guest":',
        tags: [ 'HTML' ,'CSS', 'Javascript','Firebase'],
        code: '',
        demo: 'https://free-chat-r.firebaseapp.com/',
        image: freechat
    },
    {
        id: 2,
        projectName: 'Furniture online shop',
        projectDesc: 'E-commerce website for a furtniture purchase. Fully functional application with Aoth0 authentication services and stripe payment system integration',
        tags: ['React','HTML', 'CSS','Javascript','Netlify'],
        code: 'https://github.com/EugeneMatatov/furniture',
        demo: 'https://premium-furniture.netlify.app/',
        image: furniture
    },
    {
        id: 3,
        projectName: 'Sybil attacks',
        projectDesc: 'Final B.Sc. project in Java that deals with Sybil Attacks. Interface with ability to scan social networks and search for potentially malicious behavior. Sybil Detection implements the fastest algorithm of data clustering in the world. Demonstration of data clustering and sybil attack detection available using button below',
        tags: ['Java', 'MySql', 'Matlab',],
        code: 'https://github.com/EugeneMatatov/Sybil',
        demo: 'https://www.youtube.com/watch?v=GRWbdZJ55n4',
        image: sybil
    },
    {
        id: 4,
        projectName: 'Online electronics shop',
        projectDesc: 'Online shop',
        tags: ['React', 'Express', 'MongoDB', 'Node.js'],
        code: '',
        demo: 'https://electronics-nl0m.onrender.com/',
        image: shopme
    },
    {
        id: 5,
        projectName: 'Shoes Online Store',
        projectDesc: 'Online shoes shop',
        tags: ['React', 'Express', 'MongoDB', 'Node.js'],
        code: '',
        demo: 'https://shop-client-e0tq.onrender.com/',
        image: shoes
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/