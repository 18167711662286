import resume from '../assets/pdf/resume.pdf'
import resume2 from '../data/CV.docx'
import profile from '../assets/foto4.jpg'
export const headerData = {
    name: 'Eugene Matatov',
    title: "Fullstack Developer",
    desciption:"Motivated Software Engineer with a passion for developing innovative programs seeking employment as part of a dynamic software development team.",
    image: profile,
    resumePdf: resume2
}
